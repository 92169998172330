import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { ConstantHelper } from './../../_helpers/constant.helper';
import { AppConfig } from './../../../app/app.config';
import { UserService } from './../../_services/user.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-admin-transfer-report',
  templateUrl: './admin-transfer-report.component.html',
  styleUrls: ['./admin-transfer-report.component.scss'],
})
export class AdminTransferReportComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  comapnySlug: string = ConstantHelper.COMPANY_SLUG;
  currentDate = new Date();
  dateFormat = 'MM-dd-yyyy';
  startDateVal = '';
  endDateVal = '';
  logsArr: any = [];
  tempLog: any = [];

  specialtyVal = '';
  subSpecialtyVal = '';
  acceptingPhysicianVal = '';
  requestingPhysicianVal = '';

  specialtyArr: any = [];
  subSpecialtyArr: any = [];
  acceptingPhysicianArr: any = [];
  requestingPhysicianArr: any = [];
  fileName= 'ExcelSheet.xlsx';  
  noDataFound = false;
  dateFlag = true;
  showLoader = false;
  dtOptions: DataTables.Settings = {};
  endDateLessThanStart(): boolean {
    return new Date(this.endDateVal) < new Date(this.startDateVal);
  }

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      'loggedInUserId'
    );
  }

  ngOnInit() {
    this.dtOptions = {
      order: [],
    }
  }

  searchReport() {
    this.logsArr = [];
    const newStartDate = moment(new Date(this.startDateVal.toString())).format(
      'YYYY-MM-DD'
    );
    const newEndDate = moment(new Date(this.endDateVal.toString())).format(
      'YYYY-MM-DD'
    );

    this.showLoader = true;
    const finalData = {
      companySlug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      startDate: newStartDate,
      endDate: newEndDate,
    };
    this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_findTransferLogByDateApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.findTransferLogByDate,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          console.log("Soma", res);
          this.logsArr = res.commandResult.data.logs;
          if(this.logsArr.length <= 0){
            this.noDataFound = true;
          }else{
            this.noDataFound = false;
          }
          this.tempLog = res.commandResult.data.logs;
          this.specialtyArr = res.commandResult.data.specialties;
          this.subSpecialtyArr = res.commandResult.data.subSpecialties;
          this.acceptingPhysicianArr =
            res.commandResult.data.requestedPhysicians;
          this.requestingPhysicianArr =
            res.commandResult.data.requestingPhysicians;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  filterSpecialty(specilatyId) {
    this.logsArr = this.tempLog.filter(log => log.SpecialtyId === specilatyId);
  }

  filterSubSpecialty(subSpecilatyId) {
    this.logsArr = this.tempLog.filter(log => log.SubSpecialtyId === subSpecilatyId);
  }

  filterAcceptingPhysician(physicianId) {
    this.logsArr = this.tempLog.filter(log => log.RequestedDoctorId === physicianId);
  }

  filterRequestingPhysician(physicianId) {
    this.logsArr = this.tempLog.filter(log => log.RequesitngDoctorId === physicianId);
  }



  reset() {
    this.logsArr = this.tempLog;
    this.logsArr = [];
    this.noDataFound = false;
    this.startDateVal = '';
    this.endDateVal = '';
    this.dateFlag = false;
    this.acceptingPhysicianVal='';
    this.requestingPhysicianVal='';
  }


  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('example'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
    }

    printData() {  
      var divToPrint = document.getElementById("example");  
      let newWin = window.open("");  
      newWin.document.write(divToPrint.outerHTML);  
      newWin.print();  
      newWin.close();  
  }  
}
