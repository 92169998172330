import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { DatePipe } from "@angular/common";

import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import * as moment from "moment";

@Component({
  selector: "app-admin-physician-scheduling",
  templateUrl: "./admin-physician-scheduling.component.html",
  styleUrls: ["./admin-physician-scheduling.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminPhysicianSchedulingComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  childRoute: string;
  loggedInUserId: string;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  practicingCenterId = "";
  specialtyId = "";
  subSpecialtyId = "";
  physicianId = "";
  startDateVal: any;
  startTimeHourVal = "";
  startTimeMinuteVal = "";
  endDateVal: any;
  endTimeHourVal = "";
  endTimeMinuteVal = "";
  practices: any = [];
  specialityArr: any = [];
  selectedSpacialtyId: any;
  subSpecialtyArr: any = [];
  selectedSubSpecialtyId: any;
  physiciansArr: any = [];
  docSelected: any = {};
  currentScheduleTableArr: any = [];
  pastScheduleTableArr: any = [];
  futureScheduleTableArr: any = [];

  showAddScheduleModal = false;
  showOldCheckVal = false;
  subSpecialityHeadCheckVal = false;
  toModify = false;
  selectedScheduleId;
  hourArr = [
    { key: "00", value: "00" },
    { key: "01", value: "01" },
    { key: "02", value: "02" },
    { key: "03", value: "03" },
    { key: "04", value: "04" },
    { key: "05", value: "05" },
    { key: "06", value: "06" },
    { key: "07", value: "07" },
    { key: "08", value: "08" },
    { key: "09", value: "09" },
    { key: "10", value: "10" },
    { key: "11", value: "11" },
    { key: "12", value: "12" },
    { key: "13", value: "13" },
    { key: "14", value: "14" },
    { key: "15", value: "15" },
    { key: "16", value: "16" },
    { key: "17", value: "17" },
    { key: "18", value: "18" },
    { key: "19", value: "19" },
    { key: "20", value: "20" },
    { key: "21", value: "21" },
    { key: "22", value: "22" },
    { key: "23", value: "23" },
  ];

  minuteArr = [
    { key: "00", value: "00" },
    { key: "01", value: "01" },
    { key: "02", value: "02" },
    { key: "03", value: "03" },
    { key: "04", value: "04" },
    { key: "05", value: "05" },
    { key: "06", value: "06" },
    { key: "07", value: "07" },
    { key: "08", value: "08" },
    { key: "09", value: "09" },
    { key: "10", value: "10" },
    { key: "11", value: "11" },
    { key: "12", value: "12" },
    { key: "13", value: "13" },
    { key: "14", value: "14" },
    { key: "15", value: "15" },
    { key: "16", value: "16" },
    { key: "17", value: "17" },
    { key: "18", value: "18" },
    { key: "19", value: "19" },
    { key: "20", value: "20" },
    { key: "21", value: "21" },
    { key: "22", value: "22" },
    { key: "23", value: "23" },
    { key: "24", value: "24" },
    { key: "25", value: "25" },
    { key: "26", value: "26" },
    { key: "27", value: "27" },
    { key: "28", value: "28" },
    { key: "29", value: "29" },
    { key: "30", value: "30" },
    { key: "31", value: "31" },
    { key: "32", value: "32" },
    { key: "33", value: "33" },
    { key: "34", value: "34" },
    { key: "35", value: "35" },
    { key: "36", value: "36" },
    { key: "37", value: "37" },
    { key: "38", value: "38" },
    { key: "39", value: "39" },
    { key: "40", value: "40" },
    { key: "41", value: "41" },
    { key: "42", value: "42" },
    { key: "43", value: "43" },
    { key: "44", value: "44" },
    { key: "45", value: "45" },
    { key: "46", value: "46" },
    { key: "47", value: "47" },
    { key: "48", value: "48" },
    { key: "49", value: "49" },
    { key: "50", value: "50" },
    { key: "51", value: "51" },
    { key: "52", value: "52" },
    { key: "53", value: "53" },
    { key: "54", value: "54" },
    { key: "55", value: "55" },
    { key: "56", value: "56" },
    { key: "57", value: "57" },
    { key: "58", value: "58" },
    { key: "59", value: "59" },
  ];

  companyId: any;
  coeId: any;

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private userService: UserService,
    private datePipe: DatePipe
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.companyId = this.activatedRoute.snapshot.paramMap.get("companyId");
    this.specialtyId =
      this.activatedRoute.snapshot.paramMap.get("specialityId");
    this.subSpecialtyId =
      this.activatedRoute.snapshot.paramMap.get("subSpecialityId");
    this.coeId = this.activatedRoute.snapshot.paramMap.get("coeId");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId");
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
  }

  ngOnInit() {
    this.getSpecialitiesByComapnSlug(this.companySlug);
    this.getSubSpecialitiesByComapnSlug(this.specialtyId);
    this.getParticipatingPhysiciansByComapnSlug(
      this.companySlug,
      this.specialtyId,
      this.subSpecialtyId
    );
    this.getPhysicianShedulesByComapnSlug(
      this.companySlug,
      this.specialtyId,
      this.subSpecialtyId,
      this.physicianId
    );
    this.getCOEDetails(this.coeId);
  }

  getSpecialitiesByComapnSlug(companySlug) {
    const finalData = {
      companySlug,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.getSpecialitiesByCompanySlug,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.specialityArr = apiResponse.commandResult.data.specialities;
        },
        (err) => {}
      );
  }

  getSubSpecialitiesByComapnSlug(selectedSpecialityId) {
    const finalData = {
      companySlug: this.companySlug,
      specialityId: selectedSpecialityId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.getSubSpecialitiesByComapnSlug,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.subSpecialtyArr =
            apiResponse.commandResult.data.sub_specialities;
        },
        (err) => {}
      );
  }

  getParticipatingPhysiciansByComapnSlug(
    companySlug,
    specialityId,
    subSpecialityId
  ) {
    const finalData = {
      companySlug,
      specialityId,
      subSpecialityId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findParticipatingPhysiciansBySubSpeciality,
          finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.physiciansArr = this.fnChangePhysicianNameFormat(
            apiResponse.commandResult.data.participating_physicians
          );
          if (this.physiciansArr.length > 0) {
            this.updatePracticingPhysicianInfo(this.physicianId);
          }
        },
        (err) => {}
      );
  }

  getPhysicianShedulesByComapnSlug(
    companySlug,
    specialtyId,
    subSpecialtyId,
    physicianId
  ) {
    const finalData = {
      companySlug,
      specialtyId,
      subSpecialtyId,
      physicianId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findCallSchedulesByhysicianId,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.currentScheduleTableArr =
            apiResponse.commandResult.data.currentSchedules;
          this.pastScheduleTableArr =
            apiResponse.commandResult.data.pastSchedules;
          this.futureScheduleTableArr =
            apiResponse.commandResult.data.futureSchedules;

          console.log(this.currentScheduleTableArr);
          console.log(this.pastScheduleTableArr);

          $("#example3").DataTable({
            order: [[1, "asc"]],
          });
        },
        (err) => {}
      );
  }

  getCOEDetails(coeId) {
    const finalData = {
      coeId,
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findCOEDetails,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.coeDetails.show_old_event == 1) {
            this.showOldCheckVal = true;
          }
          if (
            apiResponse.commandResult.data.coeDetails.sub_speciality_head == 1
          ) {
            this.subSpecialityHeadCheckVal = true;
          }
        },
        (err) => {}
      );
  }

  findSubSpecialitiesByComapnSlug(specialtyId) {
    this.subSpecialtyId = "";
    this.physicianId = "";
    this.showOldCheckVal = false;
    this.subSpecialityHeadCheckVal = false;
    this.currentScheduleTableArr = [];
    this.pastScheduleTableArr = [];
    this.futureScheduleTableArr = [];
    this.physiciansArr = [];
    this.getSubSpecialitiesByComapnSlug(specialtyId);
  }

  findParticipatingPhysiciansByComapnSlug(
    companySlug,
    specialityId,
    subSpecialtyId
  ) {
    this.physicianId = "";
    this.showOldCheckVal = false;
    this.subSpecialityHeadCheckVal = false;
    this.currentScheduleTableArr = [];
    this.pastScheduleTableArr = [];
    this.futureScheduleTableArr = [];
    this.physiciansArr = [];
    this.getParticipatingPhysiciansByComapnSlug(
      companySlug,
      specialityId,
      subSpecialtyId
    );
  }

  findPhysicianShedulesByComapnSlug(
    companySlug,
    specialtyId,
    subSpecialtyId,
    physicianId
  ) {
    this.showOldCheckVal = false;
    this.subSpecialityHeadCheckVal = false;
    this.currentScheduleTableArr = [];
    this.pastScheduleTableArr = [];
    this.futureScheduleTableArr = [];
    this.getPhysicianShedulesByComapnSlug(
      companySlug,
      specialtyId,
      subSpecialtyId,
      physicianId
    );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.full_name = this.userService.getUS_FromatName(e.full_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort("full_name"));
  }

  updatePracticingPhysicianInfo(physicianId) {
    this.docSelected = this.physiciansArr.find(
      (e) => e.registered_physician_id == physicianId
    );
    this.coeId = this.docSelected.id;
    this.getCOEDetails(this.docSelected.id);
  }

  async changeStatus(id) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminPhysicianSchedulingLocComp.ChangeStatus
    );
    {
      const finalData = {
        companySlug: this.companySlug,
        physicianId: this.physicianId,
        specialtyId: this.specialtyId,
        subSpecialtyId: this.subSpecialtyId,
        id,
      };

      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.changePhysicianScheduleStatusById,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            this.currentScheduleTableArr =
              apiResponse.commandResult.data.currentSchedules;
            this.pastScheduleTableArr =
              apiResponse.commandResult.data.pastSchedules;
            this.futureScheduleTableArr =
              apiResponse.commandResult.data.futureSchedules;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminphysicianSchedulingComp
                .channgeStatus
            );
          },
          (err) => {}
        );
    }
  }

  openAddScheduleModal() {
    this.toModify = false;
    this.showAddScheduleModal = true;
  }

  closeAddScheduleModal() {
    this.startTimeHourVal = "";
    this.startTimeMinuteVal = "";
    this.endTimeHourVal = "";
    this.endTimeMinuteVal = "";
    this.startDateVal = "";
    this.endDateVal = "";
    this.showAddScheduleModal = false;
  }

  openEditScheduleModal(scheduleObj) {
    this.showAddScheduleModal = true;
    this.toModify = true;
    this.selectedScheduleId = scheduleObj.id;
    this.startDateVal = new Date(scheduleObj.start_date);
    this.endDateVal = new Date(scheduleObj.end_date);
    this.startTimeHourVal = scheduleObj.start_time_hh;
    this.startTimeMinuteVal = scheduleObj.start_time_mm;
    this.endTimeHourVal = scheduleObj.end_time_hh;
    this.endTimeMinuteVal = scheduleObj.end_time_mm;
  }

  async saveSchedule() {
    this.checkDelegateStatus();
    let newStartDate = moment(new Date(this.startDateVal.toString())).format(
      "YYYY-MM-DD"
    );
    let newEndDate = moment(new Date(this.endDateVal.toString())).format(
      "YYYY-MM-DD"
    );
    const startDateTime =
      newStartDate +
      " " +
      this.startTimeHourVal +
      ":" +
      this.startTimeMinuteVal;
    const endDateTime =
      newEndDate + " " + this.endTimeHourVal + ":" + this.endTimeMinuteVal;

    if (new Date(endDateTime) < new Date(startDateTime)) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminphysicianSchedulingComp.startDateTime
      );

      return;
    }
    if (!this.toModify) {
      this.addSchedule(newStartDate, newEndDate);
    } else {
      this.modifySchedule(newStartDate, newEndDate);
    }
  }

  onSubmit() {}

  addSchedule(startDate, endDate) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      companySlug: this.companySlug,
      physicianId: this.physicianId,
      startDate,
      specialtyId: this.specialtyId,
      subSpecialtyId: this.subSpecialtyId,
      startTimeHH: this.startTimeHourVal,
      startTimeMM: this.startTimeMinuteVal,
      endDate,
      endTimeHH: this.endTimeHourVal,
      endTimeMM: this.endTimeMinuteVal,
      coeId: this.docSelected.id,
      showOldCheck: this.showOldCheckVal,
      subSpecialityHeadCheck: this.subSpecialityHeadCheckVal,
      assignedDelegateId: this.assignedDelegateId,
      isDelegated: this.isDelegated,
      selectedDelegateId: this.selectedDelegateId,
    };

    this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_savePhysicianCallScheduleApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.savePhysicianCallSchedule,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          this.startTimeHourVal = "";
          this.startTimeMinuteVal = "";
          this.endTimeHourVal = "";
          this.endTimeMinuteVal = "";
          this.startDateVal = "";
          this.endDateVal = "";
          this.currentScheduleTableArr =
            apiResponse.commandResult.data.currentSchedules;
          this.pastScheduleTableArr =
            apiResponse.commandResult.data.pastSchedules;
          this.futureScheduleTableArr =
            apiResponse.commandResult.data.futureSchedules;
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.adminphysicianSchedulingComp.addSchedule
          );
          this.showAddScheduleModal = false;
        },
        (err) => {}
      );
  }

  modifySchedule(startDate, endDate) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      startDate,
      startTimeHH: this.startTimeHourVal,
      startTimeMM: this.startTimeMinuteVal,
      endDate,
      endTimeHH: this.endTimeHourVal,
      endTimeMM: this.endTimeMinuteVal,
      scheduleId: this.selectedScheduleId,
      companySlug: this.companySlug,
      physicianId: this.physicianId,
      specialtyId: this.specialtyId,
      subSpecialtyId: this.subSpecialtyId,
      assignedDelegateId: this.assignedDelegateId,
      isDelegated: this.isDelegated,
      selectedDelegateId: this.selectedDelegateId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() +
          // ApiHelper.php_modifyPhysicianCallScheduleApi,
          ApiHelper.modifyPhysicianCallSchedule,

        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          this.startTimeHourVal = "";
          this.startTimeMinuteVal = "";
          this.endTimeHourVal = "";
          this.endTimeMinuteVal = "";
          this.startDateVal = "";
          this.endDateVal = "";
          this.currentScheduleTableArr =
            apiResponse.commandResult.data.currentSchedules;
          this.pastScheduleTableArr =
            apiResponse.commandResult.data.pastSchedules;
          this.futureScheduleTableArr =
            apiResponse.commandResult.data.futureSchedules;
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.adminphysicianSchedulingComp
              .modifySchedule
          );

          this.showAddScheduleModal = false;
        },
        (err) => {}
      );
  }

  goToDashboard() {
    if (this.isDelegated == 0) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    } else {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.assignedDelegateId,
      ]);
    }
  }

  goBack() {
    if (this.isDelegated == 0) {
      this.router.navigate([
        "/" + this.childRoute + "/practice-groups/" + this.loggedInUserId,
      ]);
    } else {
      this.router.navigate([
        "/" + this.childRoute + "/practice-groups/" + this.assignedDelegateId,
      ]);
    }
  }

  async fnShowOldCheck(e: any) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminPhysicianSchedulingLocComp
        .fnShowOldCheck
    );
    {
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        status: e.target.checked,
        coeId: this.coeId,
        companySlug: this.companySlug,
        physicianId: this.physicianId,
        specialtyId: this.specialtyId,
        subSpecialtyId: this.subSpecialtyId,
      };
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_changeOldEventStatusApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            this.pastScheduleTableArr =
              apiResponse.commandResult.data.pastSchedules;
            if (apiResponse.commandResult.data.coeDetails.show_old_event == 1) {
              this.showOldCheckVal = true;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminphysicianSchedulingComp
                  .fnShowOldCheck
              );
            } else {
              this.showOldCheckVal = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminphysicianSchedulingComp
                  .fnShowOldCheck1
              );
            }
          },
          (err) => {}
        );
    }
  }

  async fnSubSpecialityHeadCheck(e: any) {
    let altMsg = '';
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminPhysicianSchedulingLocComp
        .fnSubSpecialityHeadCheck
    );
    {
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        status: e.target.checked,
        coeId: this.coeId,
        companySlug: this.companySlug,
        physicianId: this.physicianId,
        specialtyId: this.specialtyId,
        subSpecialtyId: this.subSpecialtyId,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.changeSubspecialtyDivisionStatus,
          finalData,
          this.httpOptions
        )
        .subscribe(
         async (apiResponse: any) => {
            if (
              apiResponse.commandResult.data.coeDetails.sub_speciality_head == 1
            ) {
              this.subSpecialityHeadCheckVal = true;
            } else {
              this.subSpecialityHeadCheckVal = false;
            }
            altMsg = apiResponse.commandResult.message;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              altMsg 
              );
              window.location.reload();
          },
          (err) => {}
        );
    }
  }

  checkDelegateStatus() {
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;

    if (this.selectedDelegateId != this.loggedInUserId) {
      this.loggedInUserId = this.selectedDelegateId;
      this.assignedDelegateId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.isDelegated = 1;
    } else {
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    }
  }
}
