import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-email-all-patient",
  templateUrl: "./email-all-patient.component.html",
  styleUrls: ["./email-all-patient.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class EmailAllPatientComponent implements OnInit {
  maxChars = 300;
  fileDesTxt: any = "";
  emailSubject: any = "";
  childRoute: string;
  loggedInUserId: string;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Home Page?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    // }
  }
  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    // }
  }

  async onSubmit() {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to send email?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsComp.email2All
    );
    if (alertStatus) {
      const finalData = {
        emailSubject: this.emailSubject,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        companySlug: this.companySlug,
      };
      // console.log('finalData=>', finalData);
      this.http
        .post(
          // this.appConfig.getPhpUrl() + ApiHelper.php_sendEmail2AllApi,
          this.appConfig.getLambdaUrl06() + ApiHelper.sendEmail2All,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            // console.log('apiResponse=>', apiResponse)
            // alert("Email successfully sent to all patients.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.email2allComp.onSubmit
            );
            if(alertStatus){
              this.router.navigate(["/" + this.childRoute + "/demographics/" + this.loggedInUserId,])
            }
            // window.location.reload();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }
}
