import { Component, OnInit } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
@Component({
  selector: "app-edit-admin-user",
  templateUrl: "./edit-admin-user.component.html",
  styleUrls: ["./edit-admin-user.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class EditAdminUserComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }
  loggedInUserId: string;
  profileId: string;
  resultJsonObj: any = {};
  addAdminObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  existingEmail = false;
  existingMobile = false;
  userAdded = false;
   showLoader = false;
  ahsHippaPdf: any;
  ahsDisclaimerPdf: any;
  userRoleVal: any = 0;
  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.profileId = this.activatedRoute.snapshot.paramMap.get("profileId");
    this.userRoleVal = this.activatedRoute.snapshot.paramMap.get("userRoleVal");
    this.getProfileDetail(this.profileId);
  }

  getProfileDetail(profileId) {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {
      headers: httpHeaders,
    };

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      profileId,
    };

    // console.log(finalData);
    return this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_findUserDetailsApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserDetails06,

        finalData,
        options
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);

          this.addAdminObj.fullName =
            apiResponse.commandResult.data.UserInfo.FullName;
          this.addAdminObj.mobile =
            apiResponse.commandResult.data.UserInfo.Mobile;
          this.addAdminObj.email =
            apiResponse.commandResult.data.UserInfo.Email;
          this.addAdminObj.password =
            '';
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onSubmit(f) {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to modify the detials?")
    // )
    if(!f.dirty){
      Swal.fire({
        title:"No Change",
        text:"Details are not updated",
        showCancelButton:true,
        confirmButtonText:"OK",
        cancelButtonText:"Cancel",
      }).then((result)=>{
        if(result.value){
          this.router.navigate([
            "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
          ]);
        }
      });
    }else {
      Swal.fire({
        title:"PLEASE CONFIRM",
        text:"Are you sure you want to make these changes",
        showCancelButton:true,
        confirmButtonText:"Ok",
        cancelButtonText:"Cancel",
      }).then((result)=>{
        if(result.value){
          const finalData = {
            company_slug: ConstantHelper.COMPANY_SLUG,
            fullName: this.addAdminObj.fullName.trim(),
            mobile: this.addAdminObj.mobile.trim(),
            email: this.addAdminObj.email.trim(),
            loggedInUserId: this.loggedInUserId,
            profileId: this.profileId,
          };


          if (this.addAdminObj.password.trim()) {
            finalData['password'] = this.addAdminObj.password.trim();
          }
          this.showLoader = true;
          return this.http
          .post(
            this.appConfig.getLambdaUrl06() + ApiHelper.updateAdminProfile,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse) => {
              // console.log(apiResponse);
              // alert("Details updated successfully.");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.editAdminUserComp.onSubmit
              );
              if (this.userRoleVal > 0) {
                this.router.navigate([
                  "/" +
                    this.childRoute +
                    "/users/" +
                    this.loggedInUserId +
                    "/" +
                    this.userRoleVal,
                ]);
              } else {
                this.router.navigate([
                  "/" + this.childRoute + "/users/" + this.loggedInUserId,
                ]);
              }
            },
            (err) => {
              // console.log(err);
            }
          );
        }
      })
    }
  }
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.editadminuserComp.onSubmit
    // );
     
  

  async goToAdmins() {
    // if (confirm('PLEASE CONFIRM: Are you sure, you want to goto Administrative access list?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.editadminuserComp.goToAdmins
    );
    if(alertStatus){
      if (this.userRoleVal > 0) {
        this.router.navigate([
          "/" +
            this.childRoute +
            "/users/" +
            this.loggedInUserId +
            "/" +
            this.userRoleVal,
        ]);
      } else {
        this.router.navigate([
          "/" + this.childRoute + "/users/" + this.loggedInUserId,
        ]);
      }
    }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }
}
