import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "./../../_services/user.service";
import { UploadService } from "./../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

declare let $: any;

@Component({
  selector: "app-confirmationform1",
  templateUrl: "./confirmationform1.component.html",
  styleUrls: ["./confirmationform1.component.scss"],
})
export class Confirmationform1Component implements OnInit {
  isUploadAllowed = 0;
  documentMessage = "";
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  fileToUpload: File = null;
  fileSize = 0;
  fileName = "";
  docType = 12;
  selectedDocumentId = "";
  uploadedDocs: any = [];
  isChecked = false;
  loggedInUserId: string;
  demographicId: string;
  phyicianId: any;
  parentId: any;
  invitationId: any;
  resultJsonObj: any = {};
  documentsLength = false;
  documentsList: any;
  showLoader = false;
  ahsPdfUrl: SafeResourceUrl = "";
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showDeleteButton = false;

  message = Message;
  childRoute: string;
  AllowElectronically: any;
  practiceDocName : any;
  showUploadButton: boolean = true;
  showElectronicallyFillButton: boolean = true;
  showDocumentMessage: boolean = true;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private uploadService: UploadService,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {}

  ngOnInit() {
    $(document).ready(function () {
      $(window).resize(function () {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $(".right-box .ibox-title").height() +
        $(".right-box .ibox-content").height() -
        45;
      $(".docLists").height(rightHeight);
    }

    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
    this.invitationId =
      this.activatedRoute.snapshot.paramMap.get("invitationId");
    this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId")
      ? this.activatedRoute.snapshot.paramMap.get("parentId")
      : 0;
    this.uploadedDocs = localStorage.getItem("uploadedDocs")
      ? JSON.parse(localStorage.getItem("uploadedDocs"))
      : [];
    this.findUserDetails();
  }

  findDocumentsByDocGroup() {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getSignatureDocsByCompanyApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          localStorage.setItem("isUserIdloggedIn", "Y");
          localStorage.setItem(
            "loggedInUserId",
            this.resultJsonObj.commandResult.data.userInfo.RecordId
          );
          localStorage.setItem(
            "loggedInUserName",
            this.resultJsonObj.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem("loggedInUserComapnyId", "1");
          this.showLoader = false;

          this.findInvitationDetails(this.invitationId);
        },
        (err) => {
          this.showLoader = false;
          // console.log(err);
        }
      );
  }

  goToDemographicForm() {
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }

  // async goToSignatureForm() {
  //   if ($(".com").not(":checked").length > 0) {
  //     //   alert(
  //     //     "Please check all checkboxes or click on select all to proceed to next signature page."
  //     //   );
  //     const alertStatus = await this.sweetAlertHelper.alertPopUp(
  //       this.message.confirmMessages.confirmationFormComp.goToSignatureForm
  //     );
  //   } else {
  //     //   if (
  //     //     confirm(
  //     //       "PLEASE CONFIRM: Are you sure you want to go the signature page?"
  //     //     )
  //     //   )
  //     const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //       this.message.confirmTitle,
  //       this.message.confirmMessages.confirmationFormComp.goToSignatureForm
  //     );
  //     if (alertStatus) {
  //       this.router.navigate([
  //         "/signaturewaiver1/" +
  //           this.loggedInUserId +
  //           "/" +
  //           this.demographicId +
  //           "/" +
  //           this.phyicianId +
  //           "/" +
  //           this.invitationId +
  //           "/" +
  //           this.parentId,
  //       ]);
  //     }
  //   }
  // }

  async goToSignatureForm() { 
    // Check if any checkbox is unchecked
    const uncheckedCount = $(".com:not(:checked)").length;
  
    if (uncheckedCount > 0) {
      // Display alert if any checkbox is unchecked
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.confirmationformComp.goTosignatureForm
      );
    } else {
      // Proceed to navigate if all checkboxes are checked
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.goToSignatureForm
      );
      if (alertStatus) {
        this.router.navigate([
          "/signaturewaiver1/" +
          this.loggedInUserId +
          "/" +
          this.demographicId +
          "/" +
          this.phyicianId +
          "/" +
          this.invitationId +
          "/" +
          this.parentId,
        ]);
      }
    }
  }
  
  

  loadHippaDoc(
    event,
    documentUrl: any,
    i,
    isUploadAllowed,
    documentMessage,
    documentId,
    AllowElectronically,
    PractDocName,
  ) {
    if (event.target.checked) {
      document.getElementById("hipaaAccepted" + i).removeAttribute("disabled");
      document
        .getElementById("hipaaAccepted" + i)
        .setAttribute("checked", "checked");
      this.ahsPdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
      this.showDeleteButton = true;
      this.isUploadAllowed = isUploadAllowed;
      this.selectedDocumentId = documentId;
      this.documentMessage = documentMessage ? documentMessage : "some message";
      this.AllowElectronically=AllowElectronically
      this.practiceDocName = PractDocName
    } else {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.isChecked = false;
      this.selectedDocumentId = "";
    }
    this.showDocumentMessage = true;
    this.showUploadButton = true;
    this.showElectronicallyFillButton = true;
    // Assuming `this.documentId` is defined and holds the correct value
    // localStorage.removeItem(`${documentId}`);

  }

  viewDocument(event, documentUrl: any) {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
  }

  async checkAllHippaDoc(isChecked) {
    if (isChecked == 1) {
      //   if (
      //     confirm(
      //       "PLEASE CONFIRM: By clicking ‘Select All’ you are waiving your need to review each document individually."
      //     )
      //   )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc
      );
      if (alertStatus) {
        $(".docChecks").each(function () {
          $(this).prop("checked", true);
        });
        document.getElementById("selectAllH5").innerHTML = "Unselect All";
      } else {
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
      }
    } else {
      //   if (
      //     confirm(
      //       "PLEASE CONFIRM: By clicking ‘Unselect All’ you have to review each document individually."
      //     )
      //   )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc1
      );
      {
        document.getElementById("selectAllH5").innerHTML = "Select All";
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
      }
    }
  }

  async goToWelcome() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to leave and going back to Home Page."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async goToRegisteredDoctors() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to leave and going back to Home Page."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    {
      let documentIds = JSON.parse(sessionStorage.getItem('documentIds')) || [];        
      documentIds.forEach((id: string) => {          
          sessionStorage.removeItem(id);
        });        
      sessionStorage.removeItem('documentIds');        
        let groupDocIds = JSON.parse(sessionStorage.getItem('groupDocIds')) || [];        
        groupDocIds.forEach((id: string) => {          
          sessionStorage.removeItem(id);
        });        
        sessionStorage.removeItem('groupDocIds');
        sessionStorage.removeItem('practiceDocs');
      window.open(
        this.userService.parentSiteUrl() +
          "/registered-doctors/" +
          this.loggedInUserId,
        "_self"
      );
    }
  }

  async clearViewr() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.clearViewr
    );
    {
      $(".selectAll").prop("checked", false);
      $(".docChecks").each(function () {
        $(this).prop("checked", false);
      });
    this.showUploadButton = false;
    this.showElectronicallyFillButton = false;
    this.showDocumentMessage = false;

      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.showDeleteButton = false;
    }
  }

  findInvitationDetails(invitationId) {
    const finalData = {
      invitationId,
    };
    this.showLoader = true;

    this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_findInvitationDetailsApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.findInvitationDetails,

        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          console.log(apiResponse);
          // this.docGrouObj = apiResponse.commandResult.data.docGroupInfo;
          // this.docSet=apiResponse.commandResult.data.id;
          const docGrpId = apiResponse.commandResult.data.docGroupInfo.id;
          sessionStorage.setItem('docGrpId', docGrpId);
          console.log("docGrpId", docGrpId);

          this.documentsList = apiResponse.commandResult.data.documentList;
          this.documentsLength = true;
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;

          // console.log(err);
        }
      );
  }

  async uploadDocument(event) {
    if (this.fileUploaded.nativeElement.value !== "") {
      this.fileToUpload = event.target.files.item(0) as File;
      this.fileSize = Math.round(this.fileToUpload.size / 1024);
      this.fileName = this.fileToUpload.name;
      if (this.fileSize > 61440) {
        // alert("File is too Big, please select a file less than 60mb");

        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.adminsecondoptwfCOmp.d2d1_5
        );
        return;
      } else {
        // if (
        //   confirm("PLEASE CONFIRM: Are you sure you want to upload the file?")
        // )
        const alertStatus = await this.sweetAlertHelper.confirmPopUp(
          this.message.confirmTitle,
          this.message.confirmMessages.adminUploaddocComp.uploadFile
        );
        {
          this.showLoader = true;
          this.uploadService
            .uploadAdminReviewFile(
              this.loggedInUserId,
              this.demographicId,
              this.fileToUpload,
              this.docType,
              this.fileName,
              this.phyicianId,
              this.selectedDocumentId
            )
            .subscribe(
              async (res: any) => {
                if (res !== undefined) {
                  this.showLoader = false;
                  this.fileUploaded.nativeElement.value = "";
                  if (res.commandResult.success == 1) {
                    this.uploadedDocs.push(res.commandResult.data);
                    // alert("File successfully upload.");
                    const alertStatus = await this.sweetAlertHelper.alertPopUp(
                      this.message.alertMessages.confirmationFOrm1Comp
                        .uploadDocument
                    );
                    localStorage.setItem(
                      "uploadedDocs",
                      JSON.stringify(this.uploadedDocs)
                    );
                  } else {
                    // alert("Upload failed, try again");
                    const alertStatus = await this.sweetAlertHelper.alertPopUp(
                      this.message.alertMessages.confirmationFOrm1Comp
                        .uploadDocument1
                    );
                  }
                }
              },
              (error) => {
                this.showLoader = false;
                this.fileUploaded.nativeElement.value = "";
                console.log(error);
              }
            );
        }
      }
    }
  }

  // gotopracticeefill() {
  //       this.router.navigate([
  //           "/doc-one/" +
  //           this.loggedInUserId + "/" +
  //           this.demographicId + "/" +
  //           this.phyicianId + "/" +
  //           this.invitationId + "/" +
  //           this.parentId
  //       ]);
  //   }
    gotopracticeefill() {
      if (this.practiceDocName == "PSSPN") { 
          this.router.navigate([
              "/princeton-surgical-specialties-forms/princeton-surgical-specialties-preop-note/" +
              this.loggedInUserId + "/" +
              this.demographicId + "/" +
              this.phyicianId + "/" +
              this.invitationId + "/" +
              this.parentId +"/" +
              this.selectedDocumentId 
          ]);
      } else if (this.practiceDocName == "PSSHHF1") {
        this.router.navigate([
          "/princeton-surgical-specialties-forms/princeton-surgical-specialties-health-history-form-1/" +
          this.loggedInUserId + "/" +
          this.demographicId + "/" +
          this.phyicianId + "/" +
          this.invitationId + "/" +
          this.parentId  +"/" +
          this.selectedDocumentId   
      ]);
      } else if (this.practiceDocName == "PSSPI") {
        this.router.navigate([
          "/princeton-surgical-specialties-forms/princeton-surgical-specialties-preop-instructions/" +
          this.loggedInUserId + "/" +
          this.demographicId + "/" +
          this.phyicianId + "/" +
          this.invitationId + "/" +
          this.parentId  +"/" +
          this.selectedDocumentId 
      ]);
      } else if (this.practiceDocName == "PSSD") {
        this.router.navigate([
          "/princeton-surgical-specialties-forms/princeton-surgical-specialties-demographics/" +
          this.loggedInUserId + "/" +
          this.demographicId + "/" +
          this.phyicianId + "/" +
          this.invitationId + "/" +
          this.parentId  +"/" +
          this.selectedDocumentId  
      ]);
     } else if (this.practiceDocName == "RSCAQ2") {
      this.router.navigate([
        "/reliant-practice-docs/reliant-surgical-center-anesthesia-questionnarie-pg2/" +
        this.loggedInUserId + "/" +
        this.demographicId + "/" +
        this.phyicianId + "/" +
        this.invitationId + "/" +
        this.parentId  +"/" +
        this.selectedDocumentId 
    ]);
   } else if (this.practiceDocName == "RSCAQ1") {
    this.router.navigate([
      "/reliant-practice-docs/reliant-surgical-center-anesthesia-questionnarie-pg1/" +
      this.loggedInUserId + "/" +
      this.demographicId + "/" +
      this.phyicianId + "/" +
      this.invitationId + "/" +
      this.parentId +"/" +
      this.selectedDocumentId
  ]);
 } else if (this.practiceDocName == "PSSHHF2") {
  this.router.navigate([
    "/princeton-surgical-specialties-forms/princeton-surgical-specialties-health-history-form-2/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId  + "/" +
    this.selectedDocumentId 
 
]);
} else if (this.practiceDocName == "PNPISP") {
  this.router.navigate([
    "/princeton-rejuventation-institute-forms/pri-new-patient-intake-signature-page/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId  + "/" +
    this.selectedDocumentId  
]);
} else if (this.practiceDocName == "PAHF") {
  this.router.navigate([
    "/princeton-rejuventation-institute-forms/pri-aesthetic-history-form/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId  + "/" +
    this.selectedDocumentId 
]);
} else if (this.practiceDocName == "GCSOSF") {
  this.router.navigate([
    "/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-or-scheduling-form/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId  + "/" +
    this.selectedDocumentId 
]);
} else if (this.practiceDocName == "GCSD") {
  this.router.navigate([
    "/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-demographics/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId  + "/" +
    this.selectedDocumentId   
]);
} else if (this.practiceDocName == "PRIHHF2") {
  this.router.navigate([
    "/princeton-rejuventation-institute-forms/pri-health-history-form-2/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId  + "/" +
    this.selectedDocumentId 
]);
} else if (this.practiceDocName == "PHHF1") {
  this.router.navigate([
    "/princeton-rejuventation-institute-forms/pri-health-history-form-1/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId + "/" +
    this.selectedDocumentId
]);
} else if (this.practiceDocName == "GCSHHP2") {
  this.router.navigate([
    "/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-health-history-page-2/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId + "/" +
    this.selectedDocumentId 
]);
} else if (this.practiceDocName == "GCSHHP1") {
  this.router.navigate([
    "/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-health-history-page1/" +
    this.loggedInUserId + "/" +
    this.demographicId + "/" +
    this.phyicianId + "/" +
    this.invitationId + "/" +
    this.parentId  + "/" +
    this.selectedDocumentId   
]);
}
  //    else if (this.selectedDocumentId == "10116") {
  //     this.router.navigate([
  //       "/doc-five/" +
  //       this.loggedInUserId + "/" +
  //       this.demographicId + "/" +
  //       this.phyicianId + "/" +
  //       this.invitationId + "/" +
  //       this.parentId   
  //   ]);
  //  }
   else {
      
        alert("Permission not allowed for this doc")
        const alertStatus =
        this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.confirmationFOrm1Comp
            .permission
        );
      }
    }
  


    isCheckboxChecked(documentId: string): boolean {
      // alert(documentId)
      return sessionStorage.getItem(`${documentId}`) == "true";
    }
    areAllCheckboxesChecked(): boolean {
      return this.documentsList.every(doc => this.isCheckboxChecked(doc.RecordId));
    }
    updateSelectAllStatus() {
      this.isChecked = this.areAllCheckboxesChecked();
    }
    onCheckboxClick(event: Event, documentInfo: any, index: number): void {
      // Handle the checkbox click based on AllowElectronically value
      if (documentInfo.AllowElectronically == "0") {
        // Load the document preview (existing functionality)
        this.loadHippaDoc(event, documentInfo.DocumentUrl, index, documentInfo.isUploadAllowed, documentInfo.documentMessage, documentInfo.RecordId, documentInfo.AllowElectronically, documentInfo.PractDocName);
        
        // Check and disable the checkbox after loading the preview
        (event.target as HTMLInputElement).disabled = true;
        (event.target as HTMLInputElement).checked = true;
    
        // Save the checked status in session storage
        sessionStorage.setItem(documentInfo.RecordId, "true");
         // Manage document IDs in session storage
        let docIds = JSON.parse(sessionStorage.getItem("documentIds")) || [];

        // Add new document ID to the list if not already present
        if (!docIds.includes(Number(documentInfo.RecordId))) {
          docIds.push(Number(documentInfo.RecordId));
        }

        // Store updated list in session storage
        sessionStorage.setItem("documentIds", JSON.stringify(docIds));
      } else {
        // Handle other cases if AllowElectronically is not 0 (existing functionality)
        this.loadHippaDoc(event, documentInfo.DocumentUrl, index, documentInfo.isUploadAllowed, documentInfo.documentMessage, documentInfo.RecordId, documentInfo.AllowElectronically, documentInfo.PractDocName);
      }
    }
    
}


