import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Renderer2,
  } from "@angular/core";
  import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
  import { Router, ActivatedRoute } from "@angular/router";
  import { FormBuilder, FormGroup } from "@angular/forms";
  import { from } from "rxjs";
  import { DatePipe } from "@angular/common";
  import {
    HttpClient,
    HttpParams,
    HttpRequest,
    HttpHeaders,
  } from "@angular/common/http";
  import { SignaturePad } from "angular2-signaturepad";
  import { ApiHelper } from "src/app/_helpers/api.helper";
  import { CommonHelper } from "src/app/_helpers/common.helper";
  import { AppConfig } from "src/app/app.config";
  import { UserService } from "src/app/_services/user.service";
  import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
  import { Message } from "src/app/_locale/message";
  
  @Component({
    selector: "app-princeton-surgical-specialties-health-history-form-2",
    templateUrl: "./pri-new-patient-intake-signature-page.component.html",
    styleUrls: ["./pri-new-patient-intake-signature-page.component.scss"],
    providers: [AppConfig, CommonHelper],
  })
  export class PriNewPatientIntakeSignaturePage implements OnInit {
    httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    httpOptions = {
      headers: this.httpHeaders,
    };
    hippaJson: any = {};
    loggedInUserId: string;
    userSignatures: any = [];
    userSignatureSelected = false;
    userFullSignature = "";
    userInitialSignature = "";
    userChoosedSignature = "";
    userSignatureSelected1 = false;
    userChoosedSignature1 = "";
    userFullSignatureExit = false;
    userInitialSignatureExis = false;
    showPdfModal = false;
    signatureType: any;
    dob: any;
    age : any;
    gender : any;
    state: any;
    city: any;
    Zip: any;
    street : any;
    homeNumber: any;
    streetName: any;
    ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  
    resultJsonObj: any = {};
    clean = "0";
    @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
    @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
    canvasElm: any;
    public signaturePadOptions: Object = {
      minWidth: 1,
      canvasWidth:
        window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
      canvasHeight: 450,
    };
  
    currentDate = new Date();
    dateFormat = "MM-dd-yyyy";
  
    userWitnessSignatureSelected = false;
    userWitnessChoosedSignature = "";
  
    signatureFroWhom: any;
    isEmail: any;
    isPracticePhy: any;
    physicianId: any;
    requestId: any;
    tempId: any;
    userOptionId: any;
    emailLogId: any;
    message = Message;
    sendDocRoute = sessionStorage.getItem("sendDocRoute");
    loggedInrName: string;
    loggedInName: string;
    currentDay: string;
    operativeProcedure: string;
    demographicId: string;
    phyicianId: string;
    invitationId: string;
    parentId: string | number;
    childRoute: string;
    documentId: any;
    showLoader: boolean;
    constructor(
      public commonHelper: CommonHelper,
      private appConfig: AppConfig,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private http: HttpClient,
      private sanitizer: DomSanitizer,
      public sweetAlertHelper: SweetAlertHelper,
      public userService: UserService
    ) {
      localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
      this.childRoute = activatedRoute.snapshot.url[0].path;
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.demographicId =
        this.activatedRoute.snapshot.paramMap.get("demographicId");
      this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
      this.invitationId =
        this.activatedRoute.snapshot.paramMap.get("invitationId");
      this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId")
        ? this.activatedRoute.snapshot.paramMap.get("parentId")
        : 0;
  
      // this.loggedInUserId = localStorage.getItem("delegateId")
      //   ? localStorage.getItem("delegateId")
      //   : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      // this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      //   ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      //   : 0;
      this.isPracticePhy =
        this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
      this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
        ? this.activatedRoute.snapshot.paramMap.get("physicianId")
        : 0;
      this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
        ? this.activatedRoute.snapshot.paramMap.get("requestId")
        : 0;
  
      this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
        ? this.activatedRoute.snapshot.paramMap.get("tempId")
        : 0;
      this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
        "userOptionId"
      )
        ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
        : 0;
      this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
        ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
        : 0;
  
      this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
        localStorage.getItem("providerName")
      );
      this.hippaJson.healthcare_provider_email =
        localStorage.getItem("providerEmail");
      this.hippaJson.healthcare_provider_npi = localStorage.getItem(
        "providerPhysicianNipNo"
      );
      this.hippaJson.street_address = localStorage.getItem(
        "providerOfficeAddress"
      );
      this.hippaJson.city_zip = localStorage.getItem("providerCity")
        ? localStorage.getItem("providerCity")
        : "" + ", " + localStorage.getItem("PhysicianStateName")
        ? localStorage.getItem("PhysicianStateName")
        : "" + ", " + localStorage.getItem("providerZipCode")
        ? localStorage.getItem("providerZipCode")
        : "";
  
      this.hippaJson.providerCity = localStorage.getItem("providerCity");
      this.hippaJson.providerPhone = localStorage.getItem("providerPhone");
      this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
        "providerPhysicianNipNo"
      );
  
      
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.demographicId =
        this.activatedRoute.snapshot.paramMap.get("demographicId");
      this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
      this.invitationId =
        this.activatedRoute.snapshot.paramMap.get("invitationId");
      this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId")
        ? this.activatedRoute.snapshot.paramMap.get("parentId")
        : 0;
      this.documentId = this.activatedRoute.snapshot.paramMap.get("documentId");  
      this.fnFindSignature(this.loggedInUserId);
      this.fnFindDemographic(this.loggedInUserId);
      this.findUserDetails(this.loggedInUserId);
      this.hippaJson.patient_signature_date = this.currentDate;
      this.hippaJson.patient_signature1_date = this.currentDate;
      this.hippaJson.first_witness_signature_date = this.currentDate;
      this.hippaJson.pre_to = this.currentDate;
      this.hippaJson.post_to = this.currentDate;
      const today = new Date();
      this.currentDay = today.toISOString().split('T')[0];
    }
    ngOnInit() {
      // console.log(localStorage);
      this.hippaJson.checkbox_01 = false;
      this.hippaJson.checkbox_02 = false;
      this.hippaJson.checkbox_03 = false;
      this.hippaJson.checkbox_04 = false;
      this.hippaJson.checkbox_05 = false;
      this.hippaJson.checkbox_06 = false;
      this.hippaJson.checkbox_07 = false;
      this.hippaJson.patient_signature = "";
      this.hippaJson.patient_signature1 = "";
      this.hippaJson.first_witness_signature = "";
      this.loggedInName = localStorage.getItem('loggedInUserName');
      
    }
    formatDate(arg0: Date, dateFormat: string) {
      throw new Error("Method not implemented.");
    }

    fnFindSignature(user_id) {
      const reqData = {
        user_id,
      };
      return this.http
        .post(
          // this.appConfig.getPhpUrl() + ApiHelper.php_findUserSignatureApi,
          this.appConfig.getLambdaUrl06() + ApiHelper.findUserSignature,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
            if (response.commandResult.success == 1) {
              this.userSignatures = response.commandResult.data.userSignatures;
              this.userFullSignature =
                response.commandResult.data.userSignatures.full_signature_url;
              this.userInitialSignature =
                response.commandResult.data.userSignatures.initial_signature_url;
  
              if (response.commandResult.data.userSignatures.full_signature_url) {
                this.userFullSignatureExit = true;
              }
  
              if (
                response.commandResult.data.userSignatures.initial_signature_url
              ) {
                this.userInitialSignatureExis = true;
              }
            } else {
            }
  
            // console.log(this.userSignatures);
          },
          (err) => {}
        );
    }
  
    async onSubmit() {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.njComp.preop 
      );
      if (alertStatus) {
        const reqData = {
          loggedInUserId: this.loggedInUserId,
          patientName: this.loggedInName,
          date:this.currentDay,
          operativeDate:this.resultJsonObj.operativeDate,
          operativeProcedure:this.resultJsonObj.operativeProcedure,
          parent : this.resultJsonObj.parent,
          signature_url:this.userFullSignature,
          dob:this.dob,
          age:this.age,
          gender:this.gender,
          state: this.state,
          city: this.city,
          Zip: this.Zip,
          street : this.street,
          SSN : this.resultJsonObj.SSN,
          mail_address : this.resultJsonObj.mail_address,
          diff_city : this.resultJsonObj.diff_city,
          diff_state: this.resultJsonObj.diff_state,
          diff_zip : this.resultJsonObj.diff_zip,
          home_phone : this.resultJsonObj.home_phone,
          work_phone : this.resultJsonObj.work_phone,
          cell_phone : this.resultJsonObj.cell_phone,
          email : this.resultJsonObj.email,
          spouse_name : this.resultJsonObj.spouse_name,
          primary_phy : this.resultJsonObj.primary_phy,
          primary_phy_tel : this.resultJsonObj.primary_phy_tel,
          facility : this.resultJsonObj.facility,
          Emg_name : this.resultJsonObj.Emg_name,
          Emg_relation : this.resultJsonObj.Emg_relation,
          Emg_address : this.resultJsonObj.Emg_address,
          Emg_city : this.resultJsonObj.Emg_city,
          Emg_state : this.resultJsonObj.Emg_state,
          Emg_zip : this.resultJsonObj.Emg_zip,
          Emg_home_phone : this.resultJsonObj.Emg_home_phone,
          Emg_work_phone : this.resultJsonObj.Emg_work_phone,
          Emg_work_cell : this.resultJsonObj.Emg_work_cell,
          allergies:this.resultJsonObj.allergies,
          phyicianId: this.phyicianId,
          documentId: this.documentId,
          docType: "12",
        };
        this.showLoader = true;
        return this.http
          .post(
            this.appConfig.getLambdaUrl06()+ApiHelper.priNewPatientIntakeSignaturePage,
            reqData,
            this.httpOptions
          )
          .subscribe(
            (response: any) => {
              if (response.success == 1) {
                const docId = response.docId;
                const docName= response.docName;
                const docUrl= response.docUrl;
                console.log("docName: ",docName,"docUrl: ",docUrl);
                // Retrieve existing document IDs from sessionStorage
                let docIds = JSON.parse(sessionStorage.getItem("documentIds")) || [];                
                if (!docIds.includes(docId)) {
                  docIds.push(docId);
                }                
                sessionStorage.setItem("documentIds", JSON.stringify(docIds));
                let docGroupArrayId = JSON.parse(sessionStorage.getItem("groupDocIds")) || [];
                // Check if the document ID already exists in the array to avoid duplicates
                if (!docGroupArrayId.includes(Number(this.documentId))) {                  
                  docGroupArrayId.push(Number(this.documentId));                  
                  sessionStorage.setItem("groupDocIds", JSON.stringify(docGroupArrayId));
                }
                // Set checkbox status as true in sessionStorage
                sessionStorage.setItem(`${this.documentId}`, "true");               
                let docsArray = JSON.parse(sessionStorage.getItem("practiceDocs")) || [];              
                const newDoc = {
                    docName: docName,
                    docUrl: docUrl
                };                
                docsArray.push(newDoc);                 
                sessionStorage.setItem("practiceDocs", JSON.stringify(docsArray));
                // window.open(response.docUrl, "_blank");  
                this.showLoader = false;
                this.router.navigate([
                  "/hippa-acceptance/" +
                    this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +"/" +
                      this.demographicId +
                      "/" +
                      this.phyicianId +
                      "/" + 
                      this.invitationId +
                      "/" +
                      this.parentId
                ]);
              } else {
                if (window.open(response.commandResult.data, "_blank")) {
                  this.router.navigate([
                    "/dashboard/" +
                      this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
                  ]);
                }
              }
            },
            (err) => {}
          );
      }
    }
  
    
  
    closePdfModal() {
      this.showPdfModal = false;
      this.hippaJson.patient_signature = "";
    }
  
    openSignatureCanvas(selectedValue, signatureFroWhom) {
      this.signatureFroWhom = signatureFroWhom;
      // console.log(selectedValue);
      this.signatureType = selectedValue;
      if (
        selectedValue == "AFS" ||
        selectedValue == "AFS1" ||
        selectedValue == "AIS" ||
        selectedValue == "AWTSG"
      ) {
        this.showPdfModal = true;
      }
      if (selectedValue == "FS" || selectedValue == "IS") {
        this.userSignatureSelected = true;
        if (selectedValue == "FS") {
          this.userChoosedSignature = this.userFullSignature;
        }
        if (selectedValue == "IS") {
          this.userChoosedSignature = this.userInitialSignature;
        }
      }
    }
    
  
    ngAfterViewInit() {
      // this.signaturePad is now available
      this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
  
    drawComplete() {
      // will be notified of szimek/signature_pad's onEnd event
      // console.log(this.signaturePad.toDataURL());
    }
  
    async saveCanvas() {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.njComp.saveCanvas
      );
      // if (
      //   confirm(
      //     "PLEASE CONFIRM: This will replace your existing signature. Do you wish to change?"
      //   )
      // )
      if (alertStatus) {
        const reqData = {
          loggedInUserId: this.loggedInUserId,
          signatureBase64: this.signaturePad.toDataURL(),
          signatureType: this.signatureType,
        };
        return this.http
          .post(
            this.appConfig.getLambdaUrl05() +
              ApiHelper.name_saveSignatureImageApi,
            reqData,
            this.httpOptions
          )
          .subscribe(
            (apiResponse: any) => {
              if (apiResponse.commandResult.status == 1) {
                this.userSignatureSelected = true;
                this.userChoosedSignature =
                  apiResponse.commandResult.data.fileInfo.signatureUrl;
                // console.log(this.userChoosedSignature);
                this.fnFindSignature(this.loggedInUserId);
                this.showPdfModal = false;
                // this.hippaJson.patient_signature = '';
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  
    async clearCanvas() {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.njComp.clearCanvas
      );
      // if (
      //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
      // )
      if (alertStatus) {
        this.signaturePad.clear();
      }
    }
  
    drawStart() {}
  
    findUserDetails(userId) {
      const finalData = {
        company_slug: null,
        id: userId,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.status == 1) {
              this.hippaJson.patient_name =
                apiResponse.commandResult.data.userInfo.LastName +
                ", " +
                apiResponse.commandResult.data.userInfo.FirstName;
  
              // this.editDdemographicsformDataObj.fName = this.userService.getFirstName(
              //   apiResponse.commandResult.data.userInfo.FullName
              // );
              // this.editDdemographicsformDataObj.lName = this.userService.getLastName(
              //   apiResponse.commandResult.data.userInfo.FullName
              // );
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  
    fnFindDemographic(user_id) {
      const finalData = {
        id: user_id,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl04() +
            ApiHelper.name_findUserDemographicGeneralApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
            this.hippaJson.dob = new Date(
                this.dob=response.commandResult.data.userInfo.form1DOB,
                this.gender=response.commandResult.data.userInfo.form1Gender,
                this.age=response.commandResult.data.userInfo.form1Age,
                this.state=response.commandResult.data.userInfo.form1State,
                this.street=response.commandResult.data.userInfo.form1StreetName,
                this.city=response.commandResult.data.userInfo.form1City,
                this.Zip=response.commandResult.data.userInfo.form1ZIP, 
              );
  
            // this.hippaJson.city_zip = response.commandResult.data.userInfo.form1PrimaryPhysicianCity + ', ' + response.commandResult.data.userInfo.form1State + ', ' + response.commandResult.data.userInfo.form1ZIP;
  
            // this.hippaJson.street_address = response.commandResult.data.userInfo.form1StreetName;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  
    autoFormatSSN() {
      // alert('Amit');
      let val = this.hippaJson.social_security_no.replace(/\D/g, "");
      // val = val.replace(/^(\d{3})/, "$1-");
      // val = val.replace(/-(\d{2})/, "-$1-");
      // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
      this.hippaJson.social_security_no = val;
    }
  
    saveParentCanvas(signFor) {
      const reqData = {
        loggedInUserId: this.loggedInUserId,
        signatureBase64: this.signaturePad.toDataURL(),
        signatureType: this.signatureType,
        signFor,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl05() +
            ApiHelper.name_saveParentSignatureImageApi,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.status == 1) {
              if (signFor == "WTSG") {
                this.userWitnessSignatureSelected = true;
                this.userWitnessChoosedSignature =
                  apiResponse.commandResult.data.fileInfo.signatureUrl;
              }
              if (signFor == "AFS1") {
                this.userSignatureSelected1 = true;
                this.userChoosedSignature1 =
                  apiResponse.commandResult.data.fileInfo.signatureUrl;
              }
              this.showPdfModal = false;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
   
  
    
    goBack(){
      this.router.navigate([
        "/hippa-acceptance/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +"/" +
            this.demographicId +
            "/" +
            this.phyicianId +
            "/" + 
            this.invitationId +
            "/" +
            this.parentId
      ]);
    }
    
  }
  