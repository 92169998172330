import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "../../_services/user.service";
import { ArrayHelper } from "./../../_helpers/array.helper";

import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";
import { Location } from '@angular/common';

@Component({
  selector: "app-admin-ushf",
  templateUrl: "./admin-ushf.component.html",
  styleUrls: ["./admin-ushf.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminUshfComponent implements OnInit {
  maxChars = 300;
  fileDesTxt: any = "";
  showLoader = true;

  childRoute: string;
  loggedInUserId: string;
  demographicId: string;
  userId: string;
  resultJsonObj: any = {};
  docInfo: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  statesArre: any = [];
  checkAltEmail = false;
  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;
  cityLength = false;
  noCityLength = false;
  citiesList: any;
  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  hippaAvailable: any;
  selectedDocUrl: any;
  selectedStateCode: any;
  patientName: any;
  showComfirmationModal = false;
  hasPhysicianEmail = 1;
  selectedEmail: any;
  showInfoModal = false;
  loggedInUserRoleId:any;
  showSearchPhysicianModal = false;
  physicianSearchObj: any = {};
  doctorTableArr: any = [];
  doctorTableArrTemp: any = [];
  isSearched = false;
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  message = Message;
  physicianCount = 1;
  //message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public userService: UserService,
    public sweetAlertHelper: SweetAlertHelper,
    private location: Location,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
    this.assignedDelegateId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId")
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");

    this.findUserDetails(this.userId);
    this.findPhysicianCities();
    this.docInfo.state = "";
    this.docInfo.city = "";
    this.docInfo.physicianName = "";
  }

  findPhysicianCities() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(demographicUserId) {
    const finalData = {
      id: demographicUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Physician Home Page?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }

  async onSubmit() {
    if (this.selectedDocUrl) {
      // if (!this.selectedPhysician.altEmail) {
      //  // alert('Please enter alternet email');
      //   return;
      // } else if (this.selectedPhysician.altEmail != this.selectedPhysician.cnfAltEmail) {
      //  // alert('Please enter confirm email');
      //   return;
      // } else {
      // let conMsg = "";
      // conMsg = this.message.confirmMessages.adPhysicianUsercomp.onSubmit;
      // const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      //   this.message.confirmTitle,
      //   conMsg
      // );

      // if (confirm("PLEASE CONFIRM: Are you sure?"))

      //  if(confirmStatuszzzzzz

      if (
        !this.selectedPhysician.PhysicianEmail &&
        !this.selectedPhysician.altEmail
      ) {
        await this.sweetAlertHelper.alertPopUp(
          "Selected Physician has no email, please contact your administrator to get it, and enter in 'Physician Alternate Email' inpit box."
        );
        return;
      }

      if (
        this.selectedPhysician.altEmail &&
        this.selectedPhysician.altEmail != this.selectedPhysician.cnfAltEmail
      ) {
        await this.sweetAlertHelper.alertPopUp("Please enter confirm email");
        return;
      }

      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.addAdminUserComp.onSubmit
      );
      if (alertStatus) {
        {
          const finalData = {
            selectedDocUrl: this.selectedDocUrl,
            fileDesTxt: this.fileDesTxt,
            loggedInUserId: this.loggedInUserId,
            userId: this.userId,
            physicianNPI: this.selectedPhysician.PhysicianNipNo,
            physicianName: this.docInfo.physician_name,
            physicianAddress: this.selectedPhysician.PhysicianOfficeAddress,
            physicianPhone: this.selectedPhysician.PhysicianOfficePhone,
            physicianState: this.selectedStateCode,
            assignedDelegateId: this.assignedDelegateId,
            isDelegated: this.isDelegated,
            selectedDelegateId: this.selectedDelegateId,
            selectedEmail: this.selectedPhysician.altEmail,
            selectedPhysician: this.selectedPhysician,
          };
          this.showLoader = false;
          this.http
            .post(
              this.appConfig.getPhpUrl() + ApiHelper.php_sendUHFUserApi,
              finalData,
              this.httpOptions
            )
            .subscribe(
              async (apiResponse: any) => {
                // if (
                //   confirm(
                //     "PLEASE CONFIRM: HIPAA Form send successfully, do you want to resend the same?"
                //   )
                // )
                const alertStatus = await this.sweetAlertHelper.confirmPopUp(
                  this.message.confirmTitle,
                  this.message.confirmMessages.adminushfComp.onsubmit1
                );

                if (alertStatus) {
                  window.location.reload();
                } else {
                  if (this.isDelegated == 0) {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.loggedInUserId,
                    ]);
                  } else {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.assignedDelegateId,
                    ]);
                  }
                }
                this.showLoader = false;
              },
              (err) => {
                // console.log(err);
              }
            );
        }
      }
    } else {
      //alert('No HIPAA form found for the state.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminushfComp.onSubmit1
      );
    }
  }

  async onPrint() {
    //alert(Are you Sure to Print HIPAA)
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminushfComp.onPrint1
    ) 
    if(alertStatus){
      if (this.selectedStateCode === "NJ") {
        this.router.navigate([
          "/hippa-nj/" +
            this.loggedInUserId +
            "/" +
            this.userId +
            "/" +
            this.demographicId,
        ]);
      }
     else if (this.selectedStateCode === "NY") {
      this.router.navigate([
        "/hippa-ny/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "FL") {
      this.router.navigate([
        "/hippa-fl/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "PA") {
      this.router.navigate([
        "/hippa-pa/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "CT") {
      this.router.navigate([
        "/hippa-ct/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "OH") {
      this.router.navigate([
        "/hippa-oh/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "CO") {
      this.router.navigate([
        "/hippa-co/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "CA") {
      this.router.navigate([
        "/hippa-ca/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "AL") {
      this.router.navigate([
        "/hippa-al/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "AZ") {
      this.router.navigate([
        "/hippa-az/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "AR") {
      this.router.navigate([
        "/hippa-ar/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "DE") {
      this.router.navigate([
        "/hippa-de/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "GA") {
      this.router.navigate([
        "/hippa-ga/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "HI") {
      this.router.navigate([
        "/hippa-hi/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "ID") {
      this.router.navigate([
        "/hippa-id/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "IL") {
      this.router.navigate([
        "/hippa-il/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "AK") {
      this.router.navigate([
        "/hippa-ak/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "TX") {
      this.router.navigate([
        "/hippa-tx/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "SD") {
      this.router.navigate([
        "/hippa-sd/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "ND") {
      this.router.navigate([
        "/hippa-nd/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "VA") {
      this.router.navigate([
        "/hippa-va/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "VT") {
      this.router.navigate([
        "/hippa-vt/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "KY") {
      this.router.navigate([
        "/hippa-ky/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "WI") {
      this.router.navigate([
        "/hippa-wi/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "WV") {
      this.router.navigate([
        "/hippa-wv/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "TN") {
      this.router.navigate([
        "/hippa-tn/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "LA") {
      this.router.navigate([
        "/hippa-la/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "MD") {
      this.router.navigate([
        "/hippa-md/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "MN") {
      this.router.navigate([
        "/hippa-mn/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "MO") {
      this.router.navigate([
        "/hippa-mo/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "MS") {
      this.router.navigate([
        "/hippa-ms/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "NV") {
      this.router.navigate([
        "/hippa-nv/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "OK") {
      this.router.navigate([
        "/hippa-ok/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "WA") {
      this.router.navigate([
        "/hippa-wa/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "IA") {
      this.router.navigate([
        "/hippa-ia/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "NH") {
      this.router.navigate([
        "/hippa-nh/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "KS") {
      this.router.navigate([
        "/hippa-ks/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "OR") {
      this.router.navigate([
        "/hippa-or/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "NE") {
      this.router.navigate([
        "/hippa-ne/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "MT") {
      this.router.navigate([
        "/hippa-mt/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "NC") {
      this.router.navigate([
        "/hippa-nc/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "SC") {
      this.router.navigate([
        "/hippa-sc/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "IN") {
      this.router.navigate([
        "/hippa-in/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "ME") {
      this.router.navigate([
        "/hippa-me/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "RI") {
      this.router.navigate([
        "/hippa-ri/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "UT") {
      this.router.navigate([
        "/hippa-ut/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "NM") {
      this.router.navigate([
        "/hippa-nm/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    } else if (this.selectedStateCode === "MA") {
      this.router.navigate([
        "/hippa-ma/" +
          this.loggedInUserId +
          "/" +
          this.userId +
          "/" +
          this.demographicId,
      ]);
    }
  else {
      if (this.selectedDocUrl) {
        //alert('You need to download HIPAA form manually email to the doctor.');
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.adminushfComp.onSubmit2
        );
        window.open(this.selectedDocUrl);
        //window.location.reload();
      } else {
        //alert('No HIPAA form found for the state.');
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.adminushfComp.onSubmit1
        );
      }
    }
  }
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.userId,
        patientName: this.patientName,
        physicianNPI: this.selectedPhysician.PhysicianNipNo,
        physicianName: this.docInfo.physician_name,
        physicianAddress: this.selectedPhysician.PhysicianOfficeAddress,
        physicianPhone: this.selectedPhysician.physicianPhone,
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician,
      };

      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicianByIdApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.isDelegated == 0) {
              window.location.reload();
            } else {
              this.router.navigate([
                "/" +
                  this.childRoute +
                  "/demographics/" +
                  this.assignedDelegateId,
              ]);
            }
            this.showLoader = true;
          },
          (err) => {
            // console.log(err);
          }
        );
    //  else {
    //   //alert('No HIPAA for the state found.');
    //   const alertStatus = await this.sweetAlertHelper.alertPopUp(
    //     this.message.alertMessages.adminushfComp.onSubmit1
    //   );
    // }
  }

  findCitiesByStateCode(stateCode) {
    this.citiesList = [];
    const finalData = { stateCode };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    const finalDataPhysician = { cityName, state: this.docInfo.state };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findDoctorByCityStateApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.success == 1) {
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physiciansList = this.fnChangePhysicianNameFormat(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              //alert('No Physicians Found.');
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
            }
          } else {
            //alert('No Physicians Found.');
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  updatePhysicianInfo(physicianId) {
    this.physicianSelectDisabledOption = false;
    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    this.selectedPhysician.PhysicianEmail = this.commonHelper.cleanEmail(
      this.selectedPhysician.PhysicianEmail
    );
  }

  onChangeUserState(stateCode) {
    this.selectedStateCode = stateCode;
    this.userService.findHippaByStateCode(stateCode).subscribe(
      (apiResponse) => {
        this.resultJsonObj = apiResponse;
        if (this.resultJsonObj.commandResult.status == 1) {
          this.hippaAvailable = true;
          this.selectedDocUrl =
            this.resultJsonObj.commandResult.data.hippaDetails.DocumentUrl;
        } else {
          this.hippaAvailable = false;
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the 'Patient user' list. "
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    // );
    // if (alertStatus) {
    //   this.router.navigate([
    //     "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
    //   ]);
    // }

    this.location.back();

  }

  closeSearchPhysicianModal() {
    this.showSearchPhysicianModal = false;
    this.physicianCount = 1;
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
  }

  openSearchPhysicianModal() {
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";

    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";

    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
    this.physicianCount = 1;
  }

  async searchPhysicians() {
    this.checkDelegateStatus();
    if (this.physicianSearchObj.physicianSearchNPI){  
    if(this.physicianSearchObj.physicianSearchNPI.length !== 10 || !/^\d+$/.test(this.physicianSearchObj.physicianSearchNPI)) {
      // Display an error message for invalid NPI length
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.invalidNPI
      );
      return;
    }}
    
    if (  
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchFirstName
    ) {
      //alert('Please enter first name. You may search physician informantion by NPI number.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.seacrhPhysician1
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      //alert('Please enter last name. You may search physician informantion by NPI number.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.searchPhysician
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      //alert('Please select state.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.seacrhPhysician2
      );

      return;
    } else {
      this.doctorTableArr = [];

      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchFirstName:
          this.physicianSearchObj.physicianSearchFirstName,
        physicianSearchLastName:
          this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      this.showLoader = true;

      this.http
        .post(
          // this.appConfig.getPhpUrl() + ApiHelper.php_searchNPIRegistryApi,
          this.appConfig.getLambdaUrl06() + ApiHelper.searchNPIRegistry,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            this.doctorTableArrTemp =
              apiResponse.commandResult.data.physiciansList;
            this.doctorTableArr = apiResponse.commandResult.data.physiciansList;
            this.physicianCount = apiResponse.commandResult.data.physiciansCout;
            this.showLoader = false;
            // Check if no physicians found
        if (this.doctorTableArr.length === 0) {
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            "No physicians found for the given NPI"
          );
        }
          },

          (err) => {
            // console.log(err);
          }
        );
    }
  }

  setStateCode(stateCode) {
    this.selectedStateCode = stateCode;
  }

  selectDoctor(physicianId) {
    const tempAltEmail = this.selectedPhysician.altEmail
    const cnfAltEmail = this.selectedPhysician.cnfAltEmail
    this.isSearched = true;
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId == physicianId
    );
    this.docInfo.physicianName = this.selectedPhysician.RecordId;
    this.docInfo.physician_name = this.selectedPhysician.PhysicianName;
    this.showSearchPhysicianModal = false;
    this.docInfo.state = this.selectedPhysician.PhysicianStateCode;
    this.docInfo.stateName = this.selectedPhysician.PhysicianStateName;
    this.docInfo.city = this.selectedPhysician.PhysicianCity;
    //this.docInfo.Zip=this.selectedPhysician.p
    this.selectedPhysician.altEmail = tempAltEmail
    this.selectedPhysician.cnfAltEmail = cnfAltEmail
    const finalData = { physicianNPI: this.selectedPhysician.PhysicianNipNo };
    // console.log('===================selectedPhysician================================');
    console.log(this.selectedPhysician);
    localStorage.setItem(
      "selectedPhysicianName",
      this.selectedPhysician.PhysicianName
    );
    localStorage.setItem(
      "selectedPhysicianOfficeAddress",
      this.selectedPhysician.PhysicianOfficeAddress
    );
    localStorage.setItem(
      "selectedPhysicianNPI",
      this.selectedPhysician.PhysicianNipNo
    );
    localStorage.setItem("Mobile", this.selectedPhysician.Mobile);
    localStorage.setItem(
      "selectedPhysicianCity",
      this.selectedPhysician.PhysicianCity
    );
    localStorage.setItem(
      "selectedPhysicianState",
      this.selectedPhysician.PhysicianStateName
    );
    localStorage.setItem(
      "providerZipCode",
      this.selectedPhysician.zip_code ? this.selectedPhysician.zip_code : " "
    );
    console.log("zip", this.selectedPhysician.zip_code);

    //console.log("selected Physician:",this.selectedPhysician.PhysicianName);
    this.showLoader = true;
    this.http
      .post(
        // this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
        this.appConfig.getLambdaUrl06() + ApiHelper.findPhysicianEmail,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.onChangeUserState(this.docInfo.state);
          this.selectedEmail = apiResponse.commandResult.data.physicianEmail;
          this.hasPhysicianEmail =
            apiResponse.commandResult.data.hasPhysicianEmail;
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            // this.selectedPhysician.PhysicianEmail = this.commonHelper.cleanEmail(apiResponse.commandResult.data.physicianEmail);
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
          } else {
            this.selectedPhysician.PhysicianEmail =
              this.commonHelper.cleanEmail(
                apiResponse.commandResult.data.physicianEmail
              );
            this.showInfoModal = true;
          }

          localStorage.setItem(
            "selectedPhysicianEmail",
            this.selectedPhysician.PhysicianEmail
          );

          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat1(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  checkDelegateStatus() {
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
    // console.log( this.selectedDelegateId );
    if (this.selectedDelegateId != this.loggedInUserId) {
      this.loggedInUserId = this.selectedDelegateId;
      this.assignedDelegateId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.isDelegated = 1;
    } else {
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }
  checkEmail() {
    // Check if the alternate email matches the logged-in user's email
    if (this.selectedPhysician.altEmail && localStorage.getItem("loggedInUserEmail")) {
      if (this.selectedPhysician.altEmail.toLowerCase() === localStorage.getItem("loggedInUserEmail").toLowerCase()) {
        this.checkAltEmail = true;
      } else {
        this.checkAltEmail = false;
      }
    } else {
      this.checkAltEmail = false; // Reset to false if either email is not available
    }
  }
  isButtonDisabled(): boolean {
    this.checkEmail();
    return this.checkAltEmail;
  }
}
