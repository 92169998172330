/* tslint:disable:max-line-length */
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {UserService} from './../../_services/user.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import { Observable } from 'rxjs';

@Component({
  selector: 'patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss'],
})
export class PatientHistory implements OnInit {
  private pdfApiUrl = 'https://openaitimesheet.azurewebsites.net/reports';
  private videoApiUrl = 'https://app-videosummarize-eastus.azurewebsites.net/analyze_video';
  private imageApiUrl = 'https://app-videosummarize-eastus.azurewebsites.net/analyze_image';

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  showDataVal: any;  
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  message = Message;
  showTableData = 1;
  showLoader = false;
  delegateId = localStorage.getItem('delegateId');
  dtOptions: DataTables.Settings = {};
  profileObj : any = {};
  documentsList : any = [];
  summaryPopup : any = false;
  patientDetails : any = [];
  patientId : string;
  bmi: any;
  patientsTreatingDoctorsList : any = [];
  patientsNonTreatingDoctorsList : any = [];
  documentsLength: any;
  resultJsonObj: any = {};
  selectedAiSummary: any = {};
  summaryAnswer: string;
  isSummaryAnswer: boolean = false;
  questionToSummary: string;
  confirmSummaryPopup: any = false;
  documentComment: string;
  documentCommentDetails: any[] = [];
  documentCommentDetailsLength: boolean = false;
  saveSummaryBtn: boolean = false;
  loggedInUserName: any;
  facilityDetailsArray : any;
  openFacilityDetailsPopup : boolean = false;
  practiceDocumentsFlag : boolean = false;
  practiceDocumentsList : any = [];
  dictationList: any = [];
  adminCommentsList: boolean = false;

  commentId: any;
  showShareSummaryModal: boolean = false;
  isSummarySigned: any = 0;
  isSummaryEdited: any = 0;
  showSignedAiSummaryModal: boolean = false;

  practices: any;
  physicians1: any;
  tempPhysician01: any = [];
  physicianInfo1: any = '';
  tempPractice01: any = [];
  practiceInfo1: any = '';
  finalPhysician01: any = [];
  selectedPhysiciansIdList: any = [];

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.patientId = this.activatedRoute.snapshot.paramMap.get('demographyUserId');
  }

  ngOnInit() {
    this.showDataVal = this.activatedRoute.snapshot.paramMap.get('showDataVal');
    this.dtOptions = { order: [], pageLength: this.documentsLength};
    this.getPatientDetailsById()
    this.showData(this.showDataVal)

    
    
    this.patientDetails.BMI = this.BMI_calculator();
    this.bmi = this.BMI_calculator()

    this.fnFindAllDocuments('0');
    this.loggedInUserName = localStorage.getItem("loggedInUserName")
    this.getUserPractices()
  
  }

  BMI_calculator(){
    const totalHeightInches = this.patientDetails.HeightInFeet * 12 + '.' + (this.patientDetails.HeightInInch ? this.patientDetails.HeightInInch : 0);
    let BMI = (this.patientDetails.Weight/(+totalHeightInches*+totalHeightInches)) * 703
    return parseFloat(BMI.toFixed(2));
  }

  

  
  showData(selectedVal) {
    this.showDataVal = selectedVal;
  }


  
  showD2DEmailRequest(value) {
    if (value == 1) {
      this.showTableData = value;
    } else if (value == 2) {
      this.showTableData = value;
    } else if (value == 3) {
      this.showTableData = value;
    }
  }

  openSummaryPopup(documentId){
    this.selectedAiSummary = this.documentsList.find(
      (e) => e.RecordId == documentId
    );
    this.summaryPopup = true;
  }

  openConfirmSummaryPopup(documentId){
    this.selectedAiSummary = this.documentsList.find(
      (e) => e.RecordId == documentId
      );
    this.confirmSummaryPopup = true;
  }

  openRejectSummaryPopup(documentId){
    this.selectedAiSummary = this.documentsList.find(
      (e) => e.RecordId == documentId
    );
  }

  closeSummaryPopup(){
    this.summaryPopup = false;  
    this.confirmSummaryPopup = false;
    this.documentComment = '';
    this.summaryAnswer = '';
    this.isSummaryAnswer = false;
    this.saveSummaryBtn = false;
  }

  async getPatientDetailsById() {
    this.patientDetails = [];
    const finalData = {
      patientId: this.patientId,
    };
    this.showLoader = true;

    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getPatientDetailsById, finalData, this.httpOptions)
      .subscribe(
        (res: any) => {
          if(res){
            this.patientDetails = res.commandResult.data.patientInfo;
            this.showLoader = false;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  async updatePatientDetailsByPhysician() {
    this.bmi = this.BMI_calculator();
    const finalData = {
      loggedInUserId: localStorage.getItem('loggedInUserId'),
      patientId: this.patientId,
      heightInFeet: this.patientDetails.HeightInFeet,
      heightInInch: this.patientDetails.HeightInInch,
      weight: this.patientDetails.Weight,
      bmi: this.bmi
    };
    this.showLoader = true;

    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.updatePatientDetailsByPhysician, finalData, this.httpOptions)
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          window.location.reload();
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  async goToDemographics() {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + localStorage.getItem('loggedInUserId') + "/0"
      ]);
  }

  async onReset() {
      localStorage.removeItem("isDelegating");
      localStorage.removeItem("delegateId");
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + localStorage.getItem('loggedInUserId'),
      ]);
  }

  
  async getPatientsTreatingDoctorsList() {
    this.documentsLength = 0;
    const finalData = {
      patientId: this.patientId,
      
    };
    this.showLoader = true;
    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getPatientsTreatingDoctorsList, finalData, this.httpOptions)
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.doctorsList.length > 0){
            this.patientsTreatingDoctorsList = res.commandResult.data.doctorsList;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  async getPatientsNonTreatingDoctorsList() {
    this.documentsLength = 0;
    const finalData = {
      patientId: this.patientId,
      
    };
    this.showLoader = true;
    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getPatientsNonTreatingDoctorsList, finalData, this.httpOptions)
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.nonTreatingDoctorsList.length > 0){
            this.patientsNonTreatingDoctorsList = res.commandResult.data.nonTreatingDoctorsList;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  fnFindAllDocuments(docType) {
    this.documentsLength = 0
    let finalData;
    if(localStorage.getItem("isDelegating") == '1'){
      finalData = {
        patientId: this.patientId,
        company_slug: ConstantHelper.COMPANY_SLUG,
        email: this.profileObj.Email,
        physicianId: localStorage.getItem("delegateId"),
        userRoleId: this.loggedInUserRoleId,
      };
    } else {
      finalData = {
        patientId: this.patientId,
        company_slug: ConstantHelper.COMPANY_SLUG,
        email: this.profileObj.Email,
        physicianId: localStorage.getItem("loggedInUserId"),
        userRoleId: this.loggedInUserRoleId,
      };
    }
    this.showLoader = true;

    this.http
    .post(
            this.appConfig.getPhpUrl() + ApiHelper.php_fetchAllDocumentsForPatientHistory,
            finalData,
            this.httpOptions
        )
        .subscribe(
            (apiResponse) => {
                this.resultJsonObj = apiResponse;
                if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
                    if (docType != '0') {
                        this.documentsList = this.resultJsonObj.commandResult.data.documentList.filter(doc => doc.DocumentCategoryId == docType);
                        this.documentsLength = this.documentsList.length;
                    } else {
                        this.documentsList = this.resultJsonObj.commandResult.data.documentList;
                        this.documentsLength = this.documentsList.length;
                    }
                } else {
                    this.documentsLength = 0;
                }
                this.showLoader = false;
            },
            (err) => {}
        );
  }

  async askQuestion(){
    const extension = await this.checkDocumentType(this.selectedAiSummary.DocumentUrl);
    this.generateSummary(this.selectedAiSummary.DocumentUrl, extension);
    this.questionToSummary = '';
  }

  async checkDocumentType(url: string): Promise<string> {
    this.showLoader = true;
    try {
      const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
      const contentType = response.type;
      if (contentType === 'application/pdf') {
        this.showLoader = false;
        return 'PDF';
      } else if (contentType.startsWith('image/')) {
        this.showLoader = false;
        return 'Image';
      } else if (contentType.startsWith('video/')) {
        this.showLoader = false;
        return 'Video';
      } else {
        this.showLoader = false;
        return 'Unknown';
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      throw error;
    }
  }

  summarizeReport(documentUrl: string, extension: string): Observable<any> {
    const Pdfpayload = {
      question: this.questionToSummary ? this.questionToSummary : 'Summarize the report',
      pdf_blob_urls: [documentUrl]
    };
  
    const videoPayload = {
      video_data: documentUrl,
      video_question: this.questionToSummary ? this.questionToSummary : "if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points"
    }
  
    const imagePayload = {
      img_data: documentUrl,
      question: this.questionToSummary ? this.questionToSummary : "For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points" 
    }
  
    if(extension == 'PDF'){
      return this.http.post<any>(this.pdfApiUrl, Pdfpayload);
    } else if (extension == 'Image'){
      return this.http.post<any>(this.imageApiUrl, imagePayload);
    } else if (extension == 'Video'){
      return this.http.post<any>(this.videoApiUrl, videoPayload);
    }
  }
  

  async generateSummary(documentUrl: string, extension: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        this.showLoader = true;
        this.summarizeReport(documentUrl, extension).subscribe(
            (response) => {
                this.showLoader = false;
                this.isSummaryAnswer = true;
                this.summaryAnswer = response.answer;
                resolve();
            },
            (error) => {
                this.showLoader = false;
                console.error(error);
                reject(error);
            }
        );
    });
  }

  
  confirmOrObjectionSummary(action: string) {
    const activity = (action === 'confirmation') ? 'confirmation' : 'objection';
    const activityType = (action === 'confirmation') ? 1 : 0;

    const finalData = {
        documentId: +this.selectedAiSummary.RecordId,
        userId: localStorage.getItem("loggedInUserId"),
        activity: activity,
        documentComment: this.documentComment,
        activityType: activityType
    };
    this.showLoader = true;
    this.http
        .post(this.appConfig.getLambdaUrl05() + ApiHelper.documentConfirmObjectionPatientHistory, finalData, this.httpOptions)
        .subscribe(
            (apiResponse) => {
                this.showLoader = false;
                window.location.reload();
            },
            (err) => {
                // Handle error
            }
        );
    this.closeSummaryPopup();
}


  getConfirmationObjectionComments(documentId){
    this.documentCommentDetails = [];
    const finalData = {
      userId: +this.loggedInUserId,
      documentId: +documentId
    };
    this.showLoader = true;

    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getDocumentConfirmObjectionPatientHistory, finalData, this.httpOptions)
      .subscribe(
        (res: any) => {
          if(res){
            if(res.commandResult.data.commentsInfo.length > 0){
              this.documentCommentDetails = res.commandResult.data.commentsInfo;
              this.showLoader = false;
              this.documentCommentDetailsLength = true
            } else {
              this.showLoader = false;
              this.documentCommentDetailsLength = false;
            }
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

async generateAiSummaryByClick() {
  try {
      const extension = await this.checkDocumentType(this.selectedAiSummary.DocumentUrl);
      await this.generateSummary(this.selectedAiSummary.DocumentUrl, extension);
      await this.saveAiSummary();
  } catch (err) {
      console.error(err);
  }
}

async saveAiSummary() {
  try {
      const finalData = {
          documentId: this.selectedAiSummary.RecordId,
          aiSummary: this.summaryAnswer
      };
      this.showLoader = true;
      await this.http.post(this.appConfig.getLambdaUrl05() + ApiHelper.generateAiSummaryByClick, finalData, this.httpOptions).toPromise();
      this.showLoader = false;
      window.location.reload();
  } catch (err) {
      console.error(err);
  }
}

closeFacilityDetailsPopup(){
  this.openFacilityDetailsPopup = false;
}

openFacilityDetailsPopupWindow(documentId){
  this.facilityDetailsArray = [this.documentsList.find(
    (e) => e.RecordId == documentId
    )];
  this.openFacilityDetailsPopup = true;
}

findPracticeDocuments(){
  this.documentsLength = 0;
  const finalData = {
    userId: this.patientId
  };
  this.showLoader = true;

  this.http
      .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.findPracticeDocuments,
          finalData,
          this.httpOptions
      )
      .subscribe(
          (apiResponse: any) => {
            if(apiResponse.commandResult.data.docsList.length > 0){
              this.practiceDocumentsFlag = true;
              this.practiceDocumentsList = apiResponse.commandResult.data.docsList
            }
              this.showLoader = false;
          },
          (err) => {
              // Handle error if needed
          }
      );
}

async findDictations() {
  this.dictationList = [];
  const finalData = {
    userId: localStorage.getItem('loggedInUserId'),
    patientId: this.patientId,
    companySlug: ConstantHelper.COMPANY_SLUG,
  };
  this.showLoader = true;
  this.http
    .post(this.appConfig.getLambdaUrl05() + ApiHelper.findDictationFiles, finalData, this.httpOptions)
    .subscribe(
      (res: any) => {
        if(res.commandResult.data.Dictations.length > 0){
          this.dictationList = res.commandResult.data.Dictations.filter((item) => item.IsSummarySigned == 1);
          this.showLoader = false;
          this.adminCommentsList = true;
        }
      },
      (err) => {
        this.showLoader = false;
        console.error(err);
      }
    );
    this.showLoader = false;
}


openDictationSummary(SummaryUrl: string): void {
  if (SummaryUrl) {
    window.open(SummaryUrl, '_blank');
  } else {
    console.error('Summary URL is not available.');
  }
}

openMp3FIle(recordingUrl: string): void {
  if (recordingUrl) {
    window.open(recordingUrl, '_blank');
  } else {
    console.error('Recording URL is not available.');
  }
}

openTranscript(TranscriptUrl: string): void {
  if (TranscriptUrl) {
    window.open(TranscriptUrl, '_blank');
  } else {
    console.error('Transcript URL is not available.');
  }
}

setCommentId(commentId: any) {
  this.commentId = commentId
}



openShareSummaryModal() {
  this.showShareSummaryModal = true;
}


onChangePractice(companyUrl) {
  const reqData = {
    company_slug: companyUrl,
    loggedInUserId: this.patientId,
  };

  this.http
    .post(
      this.appConfig.getLambdaUrl06() + ApiHelper.findUserDoctorsByPractice,
      reqData,
      this.httpOptions
    )
    .subscribe(
      (apiResponse: any) => {
          this.physicians1 = apiResponse.commandResult.data.companiesList;
      },
      (err) => {
      }
    );
}

getUserPractices() {
  const finalData = {
    loggedInUserId: this.patientId,
  };
  this.showLoader = true;
  this.http
    .post(
      this.appConfig.getLambdaUrl06() + ApiHelper.findUserPractice,
      finalData,
      this.httpOptions
    )
    .subscribe(
      (apiResponse: any) => {
        this.practices = apiResponse.commandResult.data.companiesList;
        this.showLoader = false;
      },
      (err) => {
        console.error(err);
      }
    );
}


async listData() {
  if (this.physicianInfo1.length <= 0) {
    await this.sweetAlertHelper.alertPopUp('Please select practice or physician');
    return false;
  }
    if (this.physicianInfo1 == 'all') {
      this.tempPractice01.push(
        this.practices.find((x) => x.company_url == this.practiceInfo1)
      );
    } else {
      this.physicianInfo1.forEach((element) => {
        const existingPhysician = this.finalPhysician01.find((x) => x.physician_id == element);
        if (!existingPhysician) {
          const selectedPhysician = this.physicians1.find((x) => x.physician_id == element);
          if (selectedPhysician) {
            this.finalPhysician01.push(selectedPhysician);
            console.log("Mahesh", this.finalPhysician01)
            this.tempPhysician01.push(selectedPhysician);

            this.selectedPhysiciansIdList.push(selectedPhysician.physician_id);
          }
        }
      });
  }
}

onChangePhysician(value, j) {
  if (value == 'all' && j == 0) {}
  if (value == 'all' && j == 1) {}
  if (value == 'all' && j == 2) {}
  if (value == 'all' && j == 3) {}
  if (value == 'all' && j == 4) {}
}

closeEditModal() {
 
  this.showShareSummaryModal = false;
  this.showSignedAiSummaryModal = false;
}

shareSummaryWithPhysician(){

  const finalData = {
    patientId: this.patientId,
    sendingPhysicianId: localStorage.getItem('loggedInUserId'),
    receivingPhysicianId: this.selectedPhysiciansIdList,
    dictationSummaryId: this.commentId,
  };
  this.showLoader = true;
  this.http
    .post(
      this.appConfig.getLambdaUrl05() + ApiHelper.postPhysicianSharedDictationSummary,
      finalData,
      this.httpOptions
    )
    .subscribe(
      (apiResponse: any) => {
        if(apiResponse.commandResult.status == 1){
          this.showShareSummaryModal = false;
          this.selectedPhysiciansIdList = []
        }
        this.showLoader = false;
      },
      (err) => {}
    );
}


async goToUserComments() {
  this.router.navigate(['/' + this.childRoute + '/comments/' + localStorage.getItem("loggedInUserId") + '/' + this.patientId,]);
}

async goToUserVisit() {
  this.router.navigate(['/' + this.childRoute + '/admin-practice-dictation/' + localStorage.getItem("loggedInUserId") + '/' + this.patientId,]);
}

async goToUserEmailLog() {
    this.router.navigate(['/' + this.childRoute + '/email-logs/' + localStorage.getItem("loggedInUserId") + '/' + this.patientId,]);
}

async goToUserDocGroup() {
  const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    this.message.confirmTitle,
    this.message.confirmMessages.demographicsComp.goToUserDocGoupComp
  );
  if (alertStatus) {
    this.router.navigate(['/' + this.childRoute + '/assign-doc-group/' + localStorage.getItem("loggedInUserId") + '/' + this.patientId + '/' + sessionStorage.getItem('formId'),]);
  }
}

async goToUHF() {
  const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    this.message.confirmTitle,
    this.message.confirmMessages.demographicsComp.goToUHF
  );
  if (alertStatus) {
    this.router.navigate(['/' + this.childRoute + '/ushf/' + localStorage.getItem("loggedInUserId") + '/' + this.patientId + '/' + sessionStorage.getItem('formId'),]);
  }
}

async goToShareDocument() {
  const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    this.message.confirmTitle,
    this.message.confirmMessages.demographicsComp.goToDocumentUpload
  );
  if (alertStatus) {
    this.router.navigate(['/' + this.childRoute + '/upload-document/' + localStorage.getItem("loggedInUserId") + '/' + this.patientId + '/' + sessionStorage.getItem('formId'),]);
  }
}

async goTo2ndopinionWF() {
    this.router.navigate(['/' + this.childRoute + '/second-opinion-wf/' + localStorage.getItem("loggedInUserId") + '/' + this.patientId + '/' + 1]);
}

async goToPatientEmail() {
    this.router.navigate(['/' + this.childRoute + '/email2user/' + localStorage.getItem("loggedInUserId") + '/' + this.patientId]);
}
}